
import React from 'react';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

export default function LiberandoElPotencialPage() {
  return (
    <SubPageStyles >
        <Article title="Liberando el potencial de sus colaboradores para una gestión exitosa en su organización">

        <h3>¿ERES UNA VÍCTIMA O UNA PERSONA PROACTIVA? ¿CÓMO CREES QUE TE PERCIBEN LOS DEMÁS EN TU ENTORNO LABORAL?</h3>
            <p>El enfoque desde el sistema de gestión organizacional, sin importar su tamaño, ha ido cambiando en el transcurso del tiempo. Es indudable hoy, que, en el ámbito mundial, las empresas eficaces, eficientes y efectivas logran su éxito y su productividad basadas en una filosofía de protección de sus colaboradores, entiéndase esto, como mantenerlos seguros y sanos mentalmente y físicamente tanto en las áreas operativas como en las áreas administrativas.</p>
            <p>El talento humano en una organización, al sentirse <b>seguro y libre de enfermedades profesionales y mentales</b>, sentirán un mejor clima laboral y se interesarán por proteger el ambiente. Esto a su vez traerá como un efecto final la calidad en el producto que fabrican o en el servicio que prestan. La sumatoria de lo anterior, además de la productividad, obrará también sobre el balance de la organización en su P y G, al no tener costos extras por indemnizaciones de talento humano afectado, incumplimientos legales de tipo ambiental o fallas del producto o servicio. No se tendrán personas inconformes, de ninguna índole.</p>
            <p>¿Cómo llegamos a eso? a través de la premisa más efectiva de todas: la responsabilidad de la dirección y su compromiso con la gestión del sistema organizacional, evidenciando la coherencia entre lo que se piensa, lo que se dice y lo que se hace; siendo ésta última, el resultado de las dos primeras. Si estas dos características gerenciales: responsabilidad y compromiso, están presentes, el talento humano que colabora en una organización verá un liderazgo de la dirección a través del ejemplo y se generará una autoridad organizacional que va a promover el deseo en las personas de ser como ese director o esa directora, que se va convirtiendo en un modelo de imitación por parte de sus colaboradores.</p>
            <p>En las estadísticas económicas de nuestro país, la mortalidad de las pequeñas y medianas empresas es alarmante, si consideramos que de cada 100 empresas que nacen, <b>más de la mitad mueren en el primer año</b>. Esto se hace más crítico cuando el 80% de la economía del país la mueven las Pymes. La orientación estratégica organizacional, incluyendo un análisis de factibilidad de mercado y de clientes potenciales y/o usuarios, está ausente en este tipo de empresas, al igual que está ausente la consideración de los factores de vida del talento humano. Al observar empresas más estructuradas y con una presencia más robusta en el mercado, su estrategia está diseñada y desarrollada, pero el talento humano no es considerado con todo el alcance que merece para integrar las metas personales de los colaboradores con las metas organizacionales. ¿Qué deduce usted cuándo lee lo siguiente en los clasificados que ofrecen puestos de trabajo?</p>
            <p>Se solicita profesional que cumpla con… y entre las características solicitadas dice: “alta capacidad al trabajo bajo presión”. ¿Usted realmente ofrecería sus servicios cuando de antemano le están diciendo que lo van a presionar? ¿Los beneficios y estímulos podrán balancear una calidad de vida que se verá afectada por este tipo de trabajo?</p>
            <p><b>Piénselo:</b> solo con un <b>coaching personal  y un coaching organizacional bien logrados</b>, los sistemas de gestión integrada promoverán la productividad y la rentabilidad en su operación como un efecto de la obtención del costo real de fabricación; ajustado al proceso natural de la organización en donde los costos de “no calidad” se mantendrán controlados al no generar productos o servicios defectuosos, evitar los accidentes y los incumplimientos legales y éticos ambientales.</p>
            <p>Esta metodología de trabajo se puede hacer extensiva a cualquier organización, en cualquier sector económico sea privado o público. Las organizaciones deben esforzarse más por formar a su talento humano y no solo capacitarlo. La gran diferencia entre formar y capacitar radica en que la primera incluye un desarrollo personal además de los aportes a la organización, mientras que la capacitación obtiene solo beneficios para la organización.</p>
            <p>Debido a la diferencia que se ha hecho explícita es que usted podría haberse preguntado en el pasado, por qué a pesar de tantas capacitaciones y consultorías que reciben sus colaboradores, los resultados no se dan. Duda acompañada de la resultante frustración e impotencia, al ver que los recursos que invierte se esfuman en vano, y empiezan a convertirse en gastos.</p>
            <p>Por parte de la alta dirección hay exigencias hacia el talento humano en el logro de los objetivos y se despliegan capacitaciones y consultorías externas que les proporcionan más habilidades para alcanzar los objetivos, pero ¿además de la capacitación y consultoría hay formación de los colaboradores como individuos y seres humanos?, ¿cómo se asegura la alta dirección organizacional que las personas están alineando sus metas personales con las metas de la organización? Si esta formación humana no existe, en esta cadena no habrá sinergia para el balance de un buen clima laboral y un buen desempeño profesional; trabajar los procesos de coaching como un soporte fundamental a la consultoría externa que se brinda.</p>
            <p>El proceso de <b>coaching</b> acompañando las consultorías externas para sus colaboradores y para los equipos de trabajo en el desarrollo de la gestión en su organización, <b>usted despejará el potencial en cada individuo</b>, permitiéndole afrontar los diferentes retos y miedos cotidianos. Con esta integración entre los objetivos personales y los objetivos organizacionales, la empresa creará el ambiente y los espacios para que el talento humano se sienta comprometido en generar productos y/o servicios que satisfagan a sus clientes y usuarios, trabajando de una manera preventiva que evitará situaciones indeseables ante la ley, la comunidad y el gobierno.</p>
            <p>La gestión integral organizacional es el punto de partida para la productividad y el éxito en el logro de los objetivos institucionales, la eficacia, eficiencia, y ante todo, la efectividad ante la comunidad donde su organización se desempeña en la prestación del servicio o entrega del producto. Como efectos de los procesos que implemento desde la gestión integral organizacional de <b>coaching</b>  acompañando las <b>consultorías externas</b>; a corto plazo: <b>usted empezará a sentir una mejor comunicación organizacional al interior de su empresa, redundando en el buen clima laboral y en el compromiso de sus colaboradores para alcanzar las metas y resultados satisfactorios en el P y G.</b></p>

            <h3>Como conclusión</h3>
            <p>Soportada en mi experiencia, por lo que debe propender una organización es por el bienestar de su talento humano, logrando llevarlos a un adecuado balance entre los objetivos a un nivel organizacional (su trabajo y productividad laboral) y a un nivel personal (espiritual, social, familiar, recreativo, salud y financiero). Solo así tendremos organizaciones integrales que serán exitosas debido al énfasis puesto en brindar a su talento humano las oportunidades necesarias para un desarrollo integral: personal y laboral.</p>

       </Article>
    </SubPageStyles>
  );
}
